define('preconfItemsMainRouter',[
  'require',
  'exports',
  'module',
  'logger',
  'marionette',
  'app',
  'contentShowController',
  'itemPagesNameMixin'
], function (
  require,
  exports,
  module,
  Logger,
  Marionette,
  App,
  ContentShowController,
  ItemPagesNameMixin
) {
  'use strict';

  var PreconfItemsRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'preconf_items': 'showView'
    }
  });

  var API = {
    showView: function () {
      require([
        'preconfItemsShowController'
      ], function (ShowController) {
        ContentShowController.showContent().done(function () {
          App.startSubModule('preconfItemsMain');
          ShowController.showPreconfItems();
          App.execute('set:active:nav:item', ItemPagesNameMixin.PRECONF_ITEMS_LIST);
        });
      });
    }
  };

  App.on('preconfItems:show', function () {
    App.navigate('preconf_items');
    API.showView();
  });

  App.on('before:start', function () {
    new PreconfItemsRouter({
      controller: API
    });
  });

  module.exports = PreconfItemsRouter;
});
