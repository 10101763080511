define('preconfItemsKanbanItemCardView',[
  'module',
  'underscore',
  'marionette',
  'app',
  'settings',
  'moment',
  'imageUtils',
  'template!preconfItemsKanbanItemCardTpl',
  'jquery',
  'graphicalRequestKanbanAssignPopupView',
  'rolesMixin',
  'orderUtils',
  'userAssignView',
  'entities/preconf_items_kanban'
], function (
  module,
  _,
  Marionette,
  App,
  Settings,
  moment,
  imageUtils,
  viewTpl,
  $,
  PopupView,
  RolesMixin,
  OrderUtils,
  UserAssign
) {
  'use strict';

  var ItemCardView = Marionette.LayoutView.extend({
    template: viewTpl,

    events: {
      'dblclick @ui.card': '_redirectToCardDetails',
      'click .enduserid-btn': '_onEndUserClick',
      'click @ui.selector': 'onSelectClick',
      'click .action-required-customer-message': 'onActionRequiredClick',
      'click .action-required-internal-message': 'onActionRequiredClick',
      'click .action-required-ukr-message': 'onActionRequiredClick'
    },

    ui: {
      card: '.js-card-item',
      selector: '.item-selector-circle',
      selectableItem: '.card-item'
    },

    regions: {
      regionUserAssign: '.region-user-assign'
    },

    onSelectClick: function (e) {
      e.preventDefault();
      e.stopPropagation();

      var $template = this.ui.selectableItem,
        selected = $template.hasClass('selected') ? true : false;

      if (selected) {
        $template.removeClass('selected');
      } else {
        $template.addClass('selected');
      }
    },

    onActionRequiredClick: function (e) {
      e.preventDefault();
      e.stopPropagation();
      var messagePath = $(e.currentTarget).hasClass('action-required-customer-message') ? '/customer_messages' : '/internal_messages';
      var id = $(e.currentTarget).attr('data-id');
      App.navigate('#orders/' + id + messagePath, {trigger: true});
    },

    initialize: function () {
      this.users = this.options.users;
      this.preconfItems = this.options.preconfItems;
      this.contextMenuStatuses = this.options.contextMenuStatuses;
    },

    serializeData: function () {
      var templateData = this.model.toJSON();

      if (templateData.date) {
        if (typeof (templateData.date) === 'object') {
          templateData.date = moment.unix(templateData.date.time / 1000);
        } else {
          templateData.date = moment.utc(templateData.date).local().format('DD/MM HH:mm');
        }
      }
      templateData.fileUrl = this._getImageLink(this.model.get('snapshotUid'));
      templateData.productName = this.model.get('productName') ? this.model.get('productName') : '';
      templateData.cardColor = this.model.get('cardColor') ? this.model.get('cardColor') :
        (templateData.hasComment ? 'ffffa0' : '');
      var now = new Date();
      var mDate;
      if (templateData.expShippingDate) {
        if (typeof (templateData.expShippingDate) === 'object') {
          mDate = moment.unix(templateData.expShippingDate.time / 1000);
        } else {
          mDate = moment(this.model.get('expShippingDate'));
        }
        templateData.expShippingDate = mDate.format('DD/MM/YYYY');

        if (mDate.diff(now, 'days') <= 1) {
          templateData.dateColor = '#ff0000';
        } else if (mDate.diff(now, 'days') <= 3) {
          templateData.dateColor = '#ff8300';
        } else {
          templateData.dateColor = '#333333';
        }
      }

      if (templateData.lastEmailResponse) {
        if (typeof (templateData.lastEmailResponse) === 'object') {
          mDate = moment.unix(templateData.lastEmailResponse.time / 1000);
        } else {
          mDate = moment(this.model.get('lastEmailResponse'));
        }
        var diff = mDate.diff(now, 'days');
        if (-diff === 0) {
          templateData.lastEmailResponse = 'today';
        } else if (-diff === 1) {
          templateData.lastEmailResponse = 'yesterday';
        } else {
          templateData.lastEmailResponse = -diff + ' days ago';
        }

        if (diff < -10) {
          templateData.dateLastEmailResponseColor = '#ff0000';
        } else if (diff < -4) {
          templateData.dateLastEmailResponseColor = '#ff8300';
        } else {
          templateData.dateLastEmailResponseColor = '#333333';
        }
      }

      if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2]) {
        templateData.design2 = true;
      } else {
        templateData.design2 = false;
      }

      templateData.invoiceFileUidLink = this._getDownloadLink(this.model.get('invoiceFileUid'));

      templateData.state = this.options.state;
      var price = this.model.get('price');
      var qty = this.model.get('quantity');
      templateData.quantityPrice = (qty ? (qty + 'pcs - ') : '') + (price ? price.toFixed(2) + '€' : '');
      return templateData;
    },

    onShow: function () {
      this.regionUserAssign = new UserAssign({
        user: this.model.get('refUser')
      });
      this.listenTo(this.regionUserAssign, 'selected-user-modified', this._onUserAssignChange);
      this.listenTo(this.regionUserAssign, 'unassign-user', this._onUserUnAssign);
      this.getRegion('regionUserAssign').show(this.regionUserAssign);
    },

    _onUserAssignChange: function (userSecId) {
      $.when(App.request('preconf-items-kanban:assign-users', this.model.get('poi'), userSecId))
        .done(_.bind(function (data) {
          if (this.regionUserAssign) {
            if (data) {
              this.regionUserAssign.refresh(data.refUser);
            } else {
              this.regionUserAssign.refresh();
            }
          }
        }, this));
    },

    _onUserUnAssign: function () {
      $.when(App.request('preconf-items-kanban:unassigned-users', this.model.get('poi')))
        .done(_.bind(function (data) {
          if (this.regionUserAssign) {
            if (data) {
              this.regionUserAssign.refresh(data.refUser);
            } else {
              this.regionUserAssign.refresh();
            }
          }
        }, this));
    },

    _onEndUserClick: function () {
      if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_CUSTOMERS]) {
        App.navigate('customers/code/' + this.model.get('endUserId'), {trigger: true});
      }
    },

    _getDownloadLink: function (uid) {
      if (uid) {
        return Settings.url('file', uid, {cmds: 'on'});
      } else {
        return '';
      }
    },

    _getImageLink: function (snapshot) {
      if (!snapshot) {
        return Settings.get('notFoundImage');
      } else {
        return Settings.url('file', snapshot, {size: 'thumbnail'});
      }
    },

    _redirectToCardDetails: function () {
      if (App.getCurrentRoute().indexOf('preconf_items_kanban') !== -1 &&
        (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS] ||
          Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_PRECONF_ITEMS])) {
        App.navigate('orders/' + this.model.get('poi'), {trigger: true});
      }
    },

    refresh: function (newStatus) {
      this.model.set('status', newStatus);
      this.triggerMethod('refreshBoard');
    }
  });

  module.exports = ItemCardView;
});

