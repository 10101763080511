define('preconfItemsMain',[
  'module',
  'logger',
  'preconfItemsMainRouter'
], function(
  module,
  Logger
) {
	'use strict';
	
  var PreconfItems = {
    start: function() {
      Logger.log(module, 'starting PreconfItemsModule');
    },

    stop: function() {
      Logger.log(module, 'stopping PreconfItemsModule');
      $('#customer-comments-sidebar-region').hide();
    }
  };

  module.exports = PreconfItems;
});
