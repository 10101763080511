define('preconfItemsKanbanMain',[
  'module',
  'logger',
  'preconfItemsKanbanRouter'
], function (
  module,
  Logger
) {
    'use strict';

    var PreconfItemsKanbanMain = {
      start: function () {
        Logger.log(module, 'starting PreconfItemsKanbanModule');
      },

      stop: function () {
        Logger.log(module, 'stopping PreconfItemsKanbanModule');
      }
    };

    module.exports = PreconfItemsKanbanMain;
  });
