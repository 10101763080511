define('entities/orders',[
  'app',
  'backbone',
  'settings',
  'jquery',
  'underscore',
  'entities/product'
], function (
  App,
  Backbone,
  Settings,
  $,
  _
) {
  'use strict';

  var Order = Backbone.Model.extend({

    defaults: {
      pim: false,
      comments: [],
      agentId: '',
      proofsheetFileUid: '',
      exportFileUid: '',
      expShippingDate: null,
      nbrPackDelivered: 0,
      nbrPackSent: 0,
      toInvoice: false,
      internalComment: '',
      validationDate: null,
      paymentMethod: null,
      paymentTransactionNr: null,
      outsideEurope: false,
      difficultyLevel: null,
      externalNumber: null
    },

    fetch: function () {
      this.url = Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId'));
      return Backbone.Model.prototype.fetch.call(this);
    },

    getCode: function () {
      return this.get('endUserId');
    },

    getName: function () {
      return this.get('purchaseOrderId');
    },

    getImageUid: function () {
      return this.get('snapshotsUids');
    },

    getAvailableStatuses: function () {
      var that = this,
        params = {
          locale: Settings.get('lang')
        };

      if (this.get('commandType') === 'offer')
        params.tag = 'offer';

      if (this.get('commandType') === 'corporateTemplate')
        params.tag = 'corporateTemplate';


      that.trigger('statuses:request');
      return $.ajax({
        url: Settings.url('compuzz2', 'commands.json/command/status', params),
        success: function (resp) {
          that.trigger('statuses:sync', resp);
        }
      });
    },

    updateStatus: function () {
      return $.ajax({
        type: 'POST',
        url: Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/status', {
          status: this.get('status')
        }),
        contentType: false,
        dataType: false
      });
    },

    updateType: function (poi, commandType) {
      if (!poi) {
        poi = this.get('purchaseOrderId');
        commandType = this.get('commandType');
      }
      return $.ajax({
        type: 'POST',
        url: Settings.url('compuzz', 'commands.json/' + poi + '/commandType', {
          type: commandType
        }),
        contentType: false,
        dataType: false
      });
    },

    duplicate: function () {
      return $.ajax({
        type: 'POST',
        url: Settings.url('compuzz', 'commands.json/duplicate/' + this.get('purchaseOrderId'), null),
        contentType: 'application/json',
        dataType: false
      });
    },

    resetPrice: function () {
      var url = Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/price/v2/reset');
      return $.ajax({
        method: 'POST',
        url: url
      });
    },

    resetBuyingPrice: function () {
      var url = Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/price/v2/reset/buyingPrice');
      return $.ajax({
        method: 'POST',
        url: url
      });
    },

    getPriceBuyingCustom: function (customPrice, forceUpdatePrice) {
      var url = Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/price/v2/custom',
        {
          forceUpdatePrice: forceUpdatePrice,
          buyingPrice: true
        });
      return $.ajax({
        method: 'POST',
        url: url,
        data: JSON.stringify(customPrice),
        contentType: 'application/json'
      });
    },

    getPriceCustom: function (customPrice, forceUpdatePrice) {
      var url = Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/price/v2/custom',
        {forceUpdatePrice: forceUpdatePrice});
      return $.ajax({
        method: 'POST',
        url: url,
        data: JSON.stringify(customPrice),
        contentType: 'application/json'
      });
    },

    getPrice: function (realTime, buyingPrice) {
      var url = Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/price/v2',
        {
          buyingPrice: buyingPrice,
          realTime: realTime
        });
      return $.ajax({
        method: 'POST',
        url: url,
        contentType: 'application/json'
      });
    },

    getImageUrls: function (product) {
      var imageUrls = [];
      if (this.get('pim')) {
        var options = '';
        _.each(this.get('options'), function (option) {
          if (options === '') {
            options += option.id;
          } else {
            options += ',' + option.id;
          }
        });
        _.each(product.get('productPictureSecIds'), _.bind(function (productPictureSecId) {
          imageUrls.push(Settings.url('x2png', 'product/' + this.get('productBaseCode') + '/compuLogo/pim', {
            productPictureSecId: productPictureSecId,
            options: options
          }));
        }, this));
      } else {
        _.each(product.get('productPictureSecIds'), _.bind(function (productPictureSecId) {
          imageUrls.push(Settings.url('x2png', 'orders/' + this.get('purchaseOrderId') + '/products/' + this.get('productCode') +
            '/configurator', {
            productPictureSecId: productPictureSecId
          }));
        }, this));
      }

      this.trigger('image:urls:loaded', imageUrls);
    },

    changeQuantity: function (quantity) {
      var url = Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/quantity', {
        quantity: quantity
      });
      return $.ajax({
        method: 'POST',
        url: url
      });
    },

    changeProduct: function (productSecId) {
      var url = Settings.url('compuzz', 'commands.json/' + this.get('purchaseOrderId') + '/product', {
        productSecId: productSecId
      });
      return $.ajax({
        method: 'PUT',
        url: url
      });
    },

    convertPimToCompuzz: function () {
      var defer = $.Deferred();
      $.ajax({
        method: 'POST',
        url: Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/convertPimToCompuzz'),
        success: _.bind(function (response) {
          this.set(response);
          defer.resolve();
        }, this),
        error: function () {
          defer.reject();
        }
      });
      return defer.promise();
    },

    idAttribute: 'purchaseOrderId'
  });

  var OrderCollection = Backbone.Collection.extend({
    url: function () {
      return Settings.url('compuzz', '/commands.json');
    },
    model: Order
  });

  var API = {
    updateCommandType: function (poi, commandType) {
      var defer = $.Deferred();
      $.ajax({
        type: 'POST',
        url: Settings.url('compuzz', 'commands.json/' + poi + '/commandType', {
          type: commandType
        }),
        contentType: false,
        dataType: false,
        success: function (response) {
          defer.resolve(response);
        },
        complete: function (response) {
          defer.resolve(response);
        }
      });
      return defer.promise();
    },

    generateInvoice: function (data) {
      if (!Array.isArray(data)) {
        data = [data];
      }
      var url = Settings.url('compuzz2', 'commands.json/generateInvoices'),
        defer = $.Deferred();
      $.ajax({
        method: 'POST',
        url: url,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (response) {
          defer.resolve(response);
        },
        complete: function (response) {
          defer.resolve(response);
        },
        error: function () {
          defer.reject();
        }
      });
      return defer.promise();
    },

    updateOrder: function (data) {
      var url = Settings.url('compuzz2', 'commands.json/update/lite'),
        defer = $.Deferred();
      $.ajax({
        method: 'PUT',
        url: url,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (response) {
          defer.resolve(response);
        },
        complete: function (response) {
          defer.resolve(response);
        }
      });
      return defer.promise();
    },

    getOrderCollectionUrl: function (endUserId) {
      var params = {};

      if (endUserId) {
        params.endUserId = endUserId;
      }

      return Settings.url('compuzz2', 'commands.json/advancedSearch', params);
    },

    getAllOrders: function (params) {
      var url = Settings.url('compuzz2', 'commands.json/advancedSearch', params),
        defer = $.Deferred();

      $.ajax({
        method: 'get',
        url: url,
        success: function (orders) {
          var ordersList = [];
          _.each(orders, function (order) {
            ordersList.push(order.purchaseOrderId);
          });
          defer.resolve(ordersList);
        }
      });

      return defer.promise();
    },

    getAllOrdersMainInfo: function (params) {
      var url = Settings.url('compuzz2', 'commands.json/advancedSearch', params),
        defer = $.Deferred();

      $.ajax({
        method: 'get',
        url: url,
        success: function (orders) {
          var ordersList = [];
          _.each(orders, function (order) {
            ordersList.push({
              'purchaseOrderId': order.purchaseOrderId,
              'productCode': order.productCode,
              'productName': order.productName,
              'status': order.status,
              'createDate': order.createDate
            });
          });
          defer.resolve(ordersList);
        }
      });

      return defer.promise();
    },

    getOrderExcelUrl: function (params, endUserId) {

      params = params || {};

      if (endUserId) {
        params.endUserId = endUserId;
      }

      return Settings.url('compuzz', 'commands.json/excel', params);
    },

    getOrderPdfUrl: function (params) {

      params = params || {};

      return Settings.url('pdf', '', params);
    },

    getStatuses: function (type) {
      var result;
      var statuses = new OrderCollection({}),
          params = {
            locale: Settings.get('lang')
          };

      switch(type) {
        case 'offer':
          result = Settings.get('orderStatusesOffer');
          params.tag = 'offer';
          break;
        case 'corporateTemplate':
          result = Settings.get('orderStatusesCropTempl');
          params.tag = 'corporateTemplate';
          break;
        default:
          result = Settings.get('orderStatuses');
      }
      var defer = $.Deferred();

      if (result) {
        defer.resolve(result);
      } else {
        statuses.url = Settings.url('compuzz2', 'commands.json/command/status', params);
        statuses.fetch({
          success: _.bind(function (data) {
            switch(type) {
              case 'offer':
                Settings.set('orderStatusesOffer', data);
                break;
              case 'corporateTemplate':
                Settings.set('orderStatusesCropTempl', data);
                break;
              default:
                Settings.set('orderStatuses', data);
            }
            defer.resolve(data);
          }, this)
        });
      }

      return defer.promise();
    },

    getTypes: function () {
      var defer = $.Deferred();

      defer.resolve([
        {name: 'command'},
        {name: 'offer'},
        {name: 'corporateTemplate'}
        /*command,
        corporateTemplate,
        history,
        autoSave,
        autoSaveCorpTemplate,
        notPersonalized,
        offer*/
      ]);

      return defer.promise();
    },

    uploadCustomExport: function (poi, fileInfo) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz', 'commands.json/' + poi + '/customExport/upload');
      $.ajax({
        type: 'POST',
        url: url,
        data: fileInfo.formData,
        processData: false,
        contentType: false,
        complete: function (resp) {
          defer.resolve(resp);
        }
      });
      return defer.promise();
    },

    deleteCustomExport: function (poi) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz', 'commands.json/' + poi + '/customExport');

      $.ajax({
        type: 'DELETE',
        url: url,
        complete: function () {
          defer.resolve();
        }
      });

      return defer.promise();
    },

    uploadCustomProofsheet: function (poi, fileInfo) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz', 'commands.json/' + poi + '/customProofsheet/upload');
      $.ajax({
        type: 'POST',
        url: url,
        data: fileInfo.formData,
        processData: false,
        contentType: false,
        complete: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    deleteCustomProofsheet: function (poi) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz', 'commands.json/' + poi + '/customProofsheet');

      $.ajax({
        type: 'DELETE',
        url: url,
        complete: function () {
          defer.resolve();
        }
      });

      return defer.promise();
    },

    updateUseBackgroundColor: function (poi, compSecId, useBackgroundColor) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'commands.json/' + poi + '/compositions/' + compSecId,
          {
            useBackgroundColor: useBackgroundColor
          });

      $.ajax({
        type: 'PUT',
        url: url,
        complete: function () {
          defer.resolve();
        }
      });

      return defer.promise();
    },

    getOrderCountUrl: function (endUserId) {
      var params = '';

      if (endUserId) {
        params += '?endUserId=' + endUserId;
      }
      return 'commands.json/advancedSearch/rowCount' + params;
    },

    updatePrintZone: function (poi, oldPzId, newPzId) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz', 'commands.json/' + poi + '/oldprintzone/' + oldPzId + '/newprintzone/' + newPzId);
      $.ajax({
        type: 'PUT',
        url: url,
        complete: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    removePrintArea: function (poi, printArea) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz', 'commands.json/' + poi + '/removeprintarea/' + printArea);
      $.ajax({
        type: 'PUT',
        url: url,
        complete: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    addPrintArea: function (poi, printArea) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz', 'commands.json/' + poi + '/addprintarea/' + printArea);
      $.ajax({
        type: 'PUT',
        url: url,
        complete: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    updateStatus: function (poi, status) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'commands.json/' + poi + '/status', {
          status: status
        });
      $.ajax({
        type: 'POST',
        url: url,
        complete: function (resp) {
          defer.resolve(resp);
        }
      });
      return defer.promise();
    },

    getInvoicesRowCountUrl: function (purchaseOrderId) {
      return 'commands.json/' + purchaseOrderId + '/invoice/rowCount';
    },

    getInvoicesUrl: function (purchaseOrderId) {
      return Settings.url('compuzz2', 'commands.json/' + purchaseOrderId + '/invoice');
    },

    getProofsheetHistoryRowCountUrl: function (purchaseOrderId) {
      return 'commands.json/' + purchaseOrderId + '/proofsheetHistory/rowCount';
    },

    getProofsheetHistoryUrl: function (purchaseOrderId) {
      return Settings.url('compuzz2', 'commands.json/' + purchaseOrderId + '/proofsheetHistory');
    },

    generateCreditNote: function (invoiceSecId) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'v2/enduserinvoice/' + invoiceSecId + '/creditNote');
      $.ajax({
        type: 'POST',
        url: url,
        complete: function (resp) {
          defer.resolve(resp);
        }
      });
      return defer.promise();
    }
  };

  App.reqres.setHandler('order:update', function (orderModel) {
    return API.updateOrder(orderModel);
  });

  App.reqres.setHandler('order:generate-invoice', function (lstPoi) {
    return API.generateInvoice(lstPoi);
  });

  App.reqres.setHandler('order:get-collection-url', function (endUserId) {
    return API.getOrderCollectionUrl(endUserId);
  });

  App.reqres.setHandler('order:get-collection-to-page', function (params) {
    return API.getAllOrders(params);
  });

  App.reqres.setHandler('order:get-maininfo-collection-to-page', function (params) {
    return API.getAllOrdersMainInfo(params);
  });

  App.reqres.setHandler('order:get-excel-url', function (params, endUserId) {
    return API.getOrderExcelUrl(params, endUserId);
  });

  App.reqres.setHandler('order:get-pdf-url', function (params) {
    return API.getOrderPdfUrl(params);
  });

  App.reqres.setHandler('order:get-count-url', function (endUserId) {
    return API.getOrderCountUrl(endUserId);
  });

  App.reqres.setHandler('order:statuses', function (type) {
    return API.getStatuses(type);
  });

  App.reqres.setHandler('order:types', function (formated) {
    return API.getTypes(formated);
  });

  App.reqres.setHandler('order:model', function (orderJSON) {
    return new Order(orderJSON);
  });

  App.reqres.setHandler('order:upload-custom-export', function (poi, fileinfo) {
    return API.uploadCustomExport(poi, fileinfo);
  });

  App.reqres.setHandler('order:delete-custom-export', function (poi) {
    return API.deleteCustomExport(poi);
  });

  App.reqres.setHandler('order:upload-custom-proofsheet', function (poi, fileinfo) {
    return API.uploadCustomProofsheet(poi, fileinfo);
  });

  App.reqres.setHandler('order:delete-custom-proofsheet', function (poi) {
    return API.deleteCustomProofsheet(poi);
  });

  App.reqres.setHandler('order:update-use-background-color', function (poi, compSecId, useBackgroundColor) {
    return API.updateUseBackgroundColor(poi, compSecId, useBackgroundColor);
  });

  App.reqres.setHandler('order:update-type', function (poi, commandType) {
    return API.updateCommandType(poi, commandType);
  });

  App.reqres.setHandler('order:update-printzone', function (poi, oldpzId, newPzId) {
    return API.updatePrintZone(poi, oldpzId, newPzId);
  });

  App.reqres.setHandler('order:remove-print-area', function (poi, paToRemove) {
    return API.removePrintArea(poi, paToRemove);
  });

  App.reqres.setHandler('order:add-printarea', function (poi, paToAdd) {
    return API.addPrintArea(poi, paToAdd);
  });

  App.reqres.setHandler('order:update-status', function (poi, status) {
    return API.updateStatus(poi, status);
  });

  App.reqres.setHandler('order-invoices:row-count-url', function (purchaseOrderId) {
    return API.getInvoicesRowCountUrl(purchaseOrderId);
  });

  App.reqres.setHandler('order-invoices:get-url', function (purchaseOrderId) {
    return API.getInvoicesUrl(purchaseOrderId);
  });

  App.reqres.setHandler('order-proofsheet-history:row-count-url', function (purchaseOrderId) {
    return API.getProofsheetHistoryRowCountUrl(purchaseOrderId);
  });

  App.reqres.setHandler('order-proofsheet-history:get-url', function (purchaseOrderId) {
    return API.getProofsheetHistoryUrl(purchaseOrderId);
  });

  App.reqres.setHandler('order-invoices:credit-note', function (invoiceSecId) {
    return API.generateCreditNote(invoiceSecId);
  });
});

