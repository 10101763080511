define('preconfItemsKanbanBoardView',[
  'module',
  'underscore',
  'backbone',
  'marionette',
  'app',
  'jquery',
  'settings',
  'template!preconfItemsKanbanBoardTpl',
  'jqueryui'
], function (
  module,
  _,
  Backbone,
  Marionette,
  App,
  $,
  Settings,
  viewTpl
) {
    'use strict';

    var kanbanBoardView = Marionette.LayoutView.extend({
      template: viewTpl,

      events: {
        'click @ui.moveLeft': '_onScrollLeft',
        'click @ui.moveRight': '_onScrollRight',
        'dblclick .state-title': '_onDlbClickHeader'
      },

      ui: {
        moveLeft: '.js-move-left',
        moveRight: '.js-move-right',
        states: '#states-preconf-items-container'
      },

      initialize: function () {
        App.on('sidebar:resize', this.onResizeSidebar, this);
      },

      _onDlbClickHeader: function(event) {
        var st = this.$el.find(event.currentTarget).data('state');
        var status = _.findWhere(this.options.statuses, {statusConst: st});
        if (!status.main && status.childStatus && status.childStatus.length) {
          App.navigate('preconf_items_kanban/' + status.statusConst, { trigger: true });
        }
      },

      serializeData: function () {
        var templateData = {};

        templateData.boardWidth = this.options.statuses.length * 215 + 30;
        templateData.statuses = this.options.statuses;
        templateData.isScrollable = this.options.statuses.length >= 6;

        return templateData;
      },

      onShow: function () {
        $.material.init();
      },

      _onScrollLeft: function (e) {
        e.preventDefault();
        $('#preconf-items-board-region').animate({ scrollLeft: '-=220' }, 300);
      },

      _onScrollRight: function (e) {
        e.preventDefault();
        $('#preconf-items-board-region').animate({ scrollLeft: '+=220' }, 300);
      },

      onResizeSidebar: function () {
        if ($('#sidebar-region').hasClass('nav-xs')) {
          if (this.ui.moveLeft) {
            this.ui.moveLeft.addClass('small-sidebar');
          }
          if (this.$('.move-btn').hasClass('hide')) { return; }
          if (this.options.statuses.length <= 6) {
            this.$('.move-btn').addClass('hide');
          }
        } else {
          if (this.ui.moveLeft) {
            this.ui.moveLeft.removeClass('small-sidebar');
          }

          if (this.options.statuses.length < 6 && !this.$('.move-btn').hasClass('hide')) {
            this.$('.move-btn').addClass('hide');
          } else if (this.options.statuses.length >= 6 && this.$('.move-btn').hasClass('hide')) {
            this.$('.move-btn').removeClass('hide');
          }
        }
      }
    });

    module.exports = kanbanBoardView;
  });
