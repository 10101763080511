define('preconfItemsKanbanRouter',[
  'require',
  'exports',
  'module',
  'logger',
  'marionette',
  'app',
  'itemPagesNameMixin'
], function (
  require,
  exports,
  module,
  Logger,
  Marionette,
  App,
  ItemPagesNameMixin
) {
    'use strict';

    var PreconfItemsKanbanRouter = Marionette.AppRouter.extend({
      appRoutes: {
        'preconf_items_kanban': 'showView',
        'preconf_items_kanban/filter/:endUserId': 'showViewFiltered',
        'preconf_items_kanban/:parentStatus': 'showView'
      }
    });

    var API = {
      showViewFiltered: function (endUserId) {
        this.showView(null, endUserId);
      },

      showView: function (parentStatus, endUserId) {
        require([
          'preconfItemsKanbanController',
          'contentShowController'
        ], function (ShowController, ContentShowController) {
          ContentShowController.showContent().done(function () {
            App.startSubModule('preconfItemsKanbanMain');
            ShowController.showPreconfItemsKanban(parentStatus, endUserId);
            App.execute('set:active:nav:item', ItemPagesNameMixin.PRECONF_ITEMS_BOARD);
          });
        });
      }
    };

    App.on('offers_kanban:show', function () {
      App.navigate('preconf_items_kanban');
      API.showView();
    });

    App.on('before:start', function () {
      new PreconfItemsKanbanRouter({
        controller: API
      });
    });

    module.exports = PreconfItemsKanbanRouter;
  });
