/**
 * Created by BFR on 09/07/2018.
 */
define('mailsListController',[
  'module',
  'dateUtils',
  'gmailUtils',
  'backbone',
  'logger',
  'underscore',
  'app',
  'jquery',
  'mailsListView',
  'detailMailView',
  'entities/mails',
  'entities/offers',
  'entities/orders'
], function(
  module,
  dateConverter,
  GmailUtils,
  Backbone,

  Logger,
  _,
  App,
  $,
  MailsListView,
  DetailMailView
) {
  'use strict';

  var MailsController = _.extend({
    showMails: function() {
      this.getStatuses();
      if (this.gmailUtils) {
        this.gmailUtils.off('gmail_utils:loaded');
        this.gmailUtils.off('gmail_utils:signInNeeded');
      }

      if (!this.gmailUtils) {
        this.gmailUtils = GmailUtils.getInstance();
      }

      this.gmailUtils.on('gmail_utils:loaded', _.bind(function() {
        this.loadMails(this.query,'next');
      }, this));

      this.gmailUtils.on('gmail_utils:signInNeeded', _.bind(function() {
        this.view = new MailsListView({});
        App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('tabContent').show(this.view);
      }, this));

      this.gmailUtils.connect();
    },

    getStatuses: function() {
      var fetchOrderStatuses = App.request('order:statuses', false);
      var fetchOfferStatuses = App.request('offer:statuses', 'offer');
      var that = this;
      $.when(fetchOrderStatuses).done(_.bind(function (orderStatuses) {
        $.when(fetchOfferStatuses).done(_.bind(function (offerStatuses) {
          that.offerStatuses = offerStatuses;
          that.orderStatuses = orderStatuses;
        }, this));
      }, this));
    },

    loadMails: function(query,direction) {
      if (this.view && this.view.isDestroyed) {
        this.gmailUtils.clearTokens();
      }
      this.gmailUtils.listMessagesPaginated(query, direction, _.bind(function(msgs) {
        msgs = _.sortBy(msgs, 'internalDate').reverse();
        _.each(msgs, function (msg) {
          var tempDate = new Date(msg.date);
          msg.smartDate = dateConverter.isToday(msg.date) ? msg.time:dateConverter.toDateFormatString(tempDate);
          msg.date = dateConverter.toDateFormatString(tempDate);
          if (msg.labelIds.includes('UNREAD')){
            msg.unread = 'font-weight: bold;';
          }
        });
        if (this.view && !this.view.isDestroyed) {
          this.view.model = msgs;
          this.view.render();
        } else {
          this.view = new MailsListView({});
          this.subscribeEvents();
          this.view.model = msgs;
          App.regions.getRegion('main').currentView.getRegion('content').show(this.view);
        }
      }, this));
    },

    showRegion: function() {
      App.regions.getRegion('main').currentView.getRegion('content').show(this.view);
    },

    subscribeEvents: function() {
      this.view.listenTo(this.view, 'mailslist:select', _.bind(this.onMailSelected, this));
      this.view.listenTo(this.view, 'pages:navigate', _.bind(this.onNavigation, this));
      this.view.listenTo(this.view, 'mails:query', _.bind(this.onQueryMails, this));
    },

    onMailLoaded: function() {
      this.view.removeLoader();
    },

    forwardReplyMail: function(modifiedMessage,operation) {
      var that = this;
      this.gmailUtils.forwardReplyMail(operation,modifiedMessage, _.bind(function (resp) {
        if (resp.error && resp.error.code !== 200) {
          that.detailMailView.mailSent(resp.error.code + ' ' + resp.error.message);
        } else {
          that.detailMailView.mailSent();
        }
      },this));
    },

    onNavigation: function(direction) {
      this.loadMails(this.query,direction);
    },

    onQueryMails: function(query) {
      this.query = query;
      this.loadMails(query,'');
    },

    onMailSelected: function(rowId) {
      var endUserId = (_.findWhere(this.view.model, {id : rowId}).from).match(/[^@<\s]+@[^@\s>]+/)[0],
          params = {
            endUserId : endUserId,
            pageSize : 10,
            first: 0,
            _search: false,
            nd: new Date().getTime(),
            rows: 10,
           page: 1
          },
        that = this;

      $.when(App.request('offer:get-maininfo-collection-to-page', params))
        .done(_.bind(function (offers) {
          _.each(offers, function (offer) {
            offer.createDate = dateConverter.toDateFormatString(offer.createDate);
            _.each(that.offerStatuses.models, function (status) {
              if (status.attributes.statusConst === offer.status) {
                offer.status = status.attributes.status;
              }
            });
          });
          var model = _.find(this.view.model, {id: rowId});
          model.offers = offers;
          $.when(App.request('order:get-maininfo-collection-to-page', params)).done(_.bind(function (orders) {

            _.each(orders, function (order) {
              order.createDate = dateConverter.toDateFormatString(order.createDate);
              _.each(that.orderStatuses.models, function (status) {
                if (status.attributes.statusConst === order.status) {
                  order.status = status.attributes.status;
                }
              });
            });
            var model = _.find(this.view.model, {id: rowId});
            model.orders = orders;

            this.detailMailView = new DetailMailView({
              model: model
            });
            this.detailMailView.listenTo(this.detailMailView, 'mail:loaded', _.bind(this.onMailLoaded, this));
            this.detailMailView.listenTo(this.detailMailView, 'mail:refwd', _.bind(this.forwardReplyMail, this));
            var region = this.view.getRegion('mailDetail');
            if (region) {
              region.show(this.detailMailView);
            }
          }, this));

        }, this))
        .fail(function () {
          $('#content-blinder').hide();
        });
    }
  });

  module.exports = MailsController;
});
