define('entities/preconf_items_kanban',[
  'app',
  'backbone',
  'settings',
  'jquery',
  'underscore',
  'entities/product'
], function (
  App,
  Backbone,
  Settings,
  $,
  _
) {
  'use strict';
  var PreconfItem = Backbone.Model.extend({
    defaults: {
      endUserId: '',
      date: null,
      payedByCustomer: null,
      payedToManufacturer: null,
      poi: null,
      productName: '',
      snapshotUid: '',
      status: '',
      flag: '',
      externalNumber: '',
      priority: null,
      grouped: false,
      groupedInvoice: false,
      toInvoice: false,
      nbrPackDelivered: 0,
      nbrPackSent: 0,
      expShippingDate: null,
      validationDate: null,
      outsideEurope: false,
      lastEmailResponse: null,
      difficultyLevel: null
    },
    idAttribute: 'poi'
  });

  var PreconfItemCollection = Backbone.Collection.extend({
    model: PreconfItem
  });

  var API = {
    getKanbanStatuses: function () {
      var statuses = new PreconfItemCollection({}),
        params = {
          locale: Settings.get('lang'),
          tag: 'corporateTemplate'
        },
        defer = $.Deferred();

      statuses.url = Settings.url('compuzz2', 'commands.json/command/status/kanban', params);
      statuses.fetch({
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    getRowCount: function (params) {
      var url = Settings.url('compuzz2', 'commands.json/advancedSearch/rowCount', params),
        defer = $.Deferred();

      $.ajax({
        method: 'get',
        url: url,
        success: function (rowCount) {
          defer.resolve(rowCount);
        }
      });

      return defer.promise();
    },

    getAll: function (params) {
      var param = JSON.parse(JSON.stringify(params));
      var showOnlyMine = false;
      if (param.showOnlyMine && param.showOnlyMine[param.status]) {
        showOnlyMine = true;
      }
      param.showOnlyMine = showOnlyMine;
      param.types = 'corporateTemplate';
      param.pageSize = 50;
      var url = Settings.url('compuzz2', 'commands.json/lights', param),
        defer = $.Deferred(),
        collection = new PreconfItemCollection();

      $.ajax({
        method: 'get',
        url: url,
        success: _.bind(function (items) {
          var grouped = [];
          var groupedInvoice = [];
          _.each(items, _.bind(function (o) {
            var item = _.find(grouped, _.bind(function (item) {
              return item.endUserId &&
                item.endUserId === o.endUserId;
            }, this));
            if (item) {
              item.grouped = true;
              o.grouped = true;
              if (!Settings.get('offerColors')[item.endUserId]) {
                Settings.get('offerColors')[item.endUserId] = this.getRandomColor();
              }
              o.groupedColor = item.groupedColor = Settings.get('offerColors')[item.endUserId];
              grouped.splice(grouped.indexOf(item) + 1, 0, o);
            } else {
              grouped.push(o);
            }
            item = _.find(groupedInvoice, _.bind(function (offer) {
              return offer.invoiceFileUid &&
                offer.invoiceFileUid === o.invoiceFileUid;
            }, this));
            if (item) {
              item.groupedInvoice = true;
              o.groupedInvoice = true;
              if (!Settings.get('offerColorsInvoice')[item.invoiceFileUid]) {
                Settings.get('offerColorsInvoice')[item.invoiceFileUid] = this.getRandomColor();
              }
              o.groupedColorInvoice = item.groupedColorInvoice = Settings.get('offerColorsInvoice')[item.invoiceFileUid];
              groupedInvoice.splice(groupedInvoice.indexOf(item) + 1, 0, o);
            } else {
              groupedInvoice.push(o);
            }
          }, this));
          collection = new PreconfItemCollection(grouped);
          var url2 = Settings.url('compuzz2', 'commands.json/lights/rowCount', param);
          $.ajax({
            method: 'get',
            url: url2,
            success: _.bind(function (count) {
              defer.resolve(collection, count);
            })
          });
        }, this)
      });

      return defer.promise();
    },

    getRandomColor: function () {
      var letters = '0123456789ABCDEF'.split('');
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }

      return color;
    },

    getComment: function (poi) {
      var url = Settings.url('compuzz2', 'commands.json/' + poi + '/comment'),
        defer = $.Deferred();
      $.ajax({
        method: 'GET',
        url: url,
        success: function (comment) {
          defer.resolve(comment);
        }
      });
      return defer.promise();
    },

    updateComment: function (poi, comment) {
      var url = Settings.url('compuzz2', 'commands.json/' + poi + '/comment'),
        defer = $.Deferred();
      $.ajax({
        method: 'PUT',
        url: url,
        data: JSON.stringify(comment),
        contentType: 'application/json',
        success: function (response) {
          defer.resolve(response);
        }
      });
      return defer.promise();
    },

    updateCard: function (poi, status) {
      return $.ajax({
        type: 'POST',
        url: Settings.url('compuzz2', 'commands.json/' + poi + '/status', {
          status: status
        }),
        contentType: false,
        dataType: false
      });
    },

    getModel: function (content) {
      return new PreconfItem(content);
    },

    assignUser: function (poi, userSecId) {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz2', 'commands.json/' + poi + '/assign', {user: userSecId}),
        type: 'PUT',
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    unassignUser: function (poi) {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz2', 'commands.json/' + poi + '/unassign'),
        type: 'PUT',
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    createFromConfiguration: function (productSecId, endUserId, customerId, configuration) {
      var config = {
        quantity: configuration.quantity,
        options: [],
        printZonesColors: {},
        printZonesImages: {},
        attachments: configuration.attachments,
        comment: configuration.comment
      };

      for (var propertyName in configuration.options) {
        if (configuration.options.hasOwnProperty(propertyName)) {
          config.options.push(configuration.options[propertyName]);
        }
      }

      for (var propertyName1 in configuration.printZones) {
        if (configuration.printZones.hasOwnProperty(propertyName1)) {
          var pzConf = configuration.printZones[propertyName1];
          config.printZonesColors[pzConf.pzId] = pzConf.nbrColor;
        }
      }

      for (var propertyName2 in configuration.printZonesImages) {
        if (configuration.printZonesImages.hasOwnProperty(propertyName2)) {
          var pzConf2 = configuration.printZonesImages[propertyName2];
          config.printZonesImages[pzConf2.pzId] = pzConf2.fileContent;
        }
      }

      var params = {
        endUserId: endUserId,
        customerId: customerId
      };
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'commands.json/create/corporateTemplate/' + productSecId, params);
      $.ajax({
        type: 'POST',
        url: url,
        data: JSON.stringify(config),
        contentType: 'application/json',
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    getHistoryRowCount: function (purchaseOrderId, options) {
      options = options || {};
      _.defaults(options, {});
      var params = '';
      return 'commands.json/' + purchaseOrderId + '/history/rowCount' + params;
    },

    getHistory: function (purchaseOrderId, options) {
      options = options || {};
      return Settings.url('compuzz2', 'commands.json/' + purchaseOrderId + '/history', options);
    }
  };

  App.reqres.setHandler('preconf-items-kanban:statuses', function () {
    return API.getKanbanStatuses();
  });
  App.reqres.setHandler('preconf-items-kanban:all', function (params) {
    return API.getAll(params);
  });
  App.reqres.setHandler('preconf-items_kanban:row:count', function (params) {
    return API.getRowCount(params);
  });
  App.reqres.setHandler('preconf-items-kanban:update:table', function (poi, status) {
    return API.updateCard(poi, status);
  });
  App.reqres.setHandler('preconf-items-kanban:update:comment', function (poi, comment) {
    return API.updateComment(poi, comment);
  });
  App.reqres.setHandler('offer-kanban:get:comment', function (poi) {
    return API.getComment(poi);
  });
  App.reqres.setHandler('preconf-items-kanban:new:model', function (content) {
    return API.getModel(content);
  });
  App.reqres.setHandler('preconf-items-kanban:assign-users', function (ticketSecId, userSecId) {
    return API.assignUser(ticketSecId, userSecId);
  });
  App.reqres.setHandler('preconf-items-kanban:unassigned-users', function (ticketSecId) {
    return API.unassignUser(ticketSecId);
  });
  App.reqres.setHandler('preconf-items-kanban:create-from-configuration', function (productSecId, endUserId, customerId, config) {
    return API.createFromConfiguration(productSecId, endUserId, customerId, config);
  });
  App.reqres.setHandler('preconf-items-kanban:history-get', function (purchaseOrderId, params) {
    return API.getHistory(purchaseOrderId, params);
  });
  App.reqres.setHandler('preconf-items-kanban:history-row-count', function (purchaseOrderId, params) {
    return API.getHistoryRowCount(purchaseOrderId, params);
  });
});
