
define('preconfItemsShowController',[
  'module',
  'logger',
  'underscore',
  'app',
  'bootbox',
  'emptyView',
  'preconfItemsShowView',
  'jquery',
  'controllerMixin',
  'productSelectDialogView',
  'productConfigDialogView',
  'selectCustomerPopupView',
  'entities/preconf_items'
], function (
  module,
  Logger,
  _,
  App,
  bootbox,
  EmptyView,
  View,
  $,
  ControllerMixin,
  ProductSelectDialog,
  ProductConfigDialog,
  CustomerSelectDialog
) {
  'use strict';

  var PreconfItemsController = _.extend({
    showPreconfItems: function (data, ViewInstance, showComments) {
      if (!showComments) {
        $('#customer-comments-sidebar-region').hide();
      }
      this.createView(data, ViewInstance || View);
      this.subscribeEvents();
      this.showRegion();
    },

    createView: function (data, ViewInstance) {
      var params = data ? {
        endUserSecId: data.model.get('secId')
      } : undefined;
      var view = new ViewInstance(params);
      this.view = view;
      this.data = data;
    },

    showRegion: function () {
      App.regions.getRegion('main').currentView.getRegion('content').show(this.view);
    },

    subscribeEvents: function () {
      this.view.listenTo(this.view, 'addNew', _.bind(this.onAddNew, this));
      this.view.listenTo(this.view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
      this.view.listenTo(this.view, 'onLoadSelectedPreconfItems', _.bind(this.onLoadSelectedPreconfItems, this));
      this.view.listenTo(this.view, 'preconf-items:update', _.bind(this.onOfferUpdate, this));
    },

    onAddNew: function () {
      var currentRoute = App.getCurrentRoute();
      if (currentRoute !== 'preconf_items' && this.data && this.data.model.get('secId')) {
        this.openProductDialog();
      } else {
        this.openEndUserDialog();
      }
    },

    openEndUserDialog: function () {
      this.customerSelectDialog = new CustomerSelectDialog();
      this.customerSelectDialog.listenTo(this.customerSelectDialog, 'customer-selected', _.bind(this.onCustomerSelected, this));
      this.customerSelectDialog.show({
        title: _.i18n('dialog.offer.selectCustomer')
      });
    },

    onCustomerSelected: function (selectedCustomer) {
      this.data = {
        model: selectedCustomer
      };
      this.openProductDialog();
    },

    openProductDialog: function () {
      this.productSelectDialog = new ProductSelectDialog({
        multiSelect: false,
        displayLeaveButton: false
      });
      this.productSelectDialog.listenTo(this.productSelectDialog, 'products-selected', _.bind(this.onProductsSelected, this));
      this.productSelectDialog.show({
        title: _.i18n('dialog.offer.selectProduct'),
        className: 'product-selection-dialog'
      });
    },

    onProductsSelected: function (products) {
      this.currentproducts = _.pluck(products, 'rowid');
      this.displayProductConfigPopup();
    },

    displayProductConfigPopup: function () {
      var selectedProduct = App.request('products:model', {secId: this.currentproducts[0]});
      selectedProduct.fetch().done(_.bind(function () {
        selectedProduct.fullFetch(selectedProduct.get('cCode')).done(_.bind(function () {
          this.productConfigDialog = new ProductConfigDialog({product: selectedProduct});
          this.productSelectDialog.hide();
          this.productConfigDialog.show({
            title: _.i18n('dialog.offer.selectProduct'),
            className: 'product-selection-dialog'
          });
          this.productConfigDialog.listenTo(this.productConfigDialog, 'product-configured', _.bind(this.onProductConfigured, this));
          this.productConfigDialog.listenTo(this.productConfigDialog, 'change-product', _.bind(this.onChangeProduct, this));
        }, this));
      }, this));
    },

    onChangeProduct: function () {
      this.productConfigDialog.hide();
      this.openProductDialog();
    },

    onProductConfigured: function (configuration) {
      App.request('preconf-items:create-from-configuration',
        this.productConfigDialog.model.get('secId'),
        this.data ? this.data.model.get('endUserId') : '',
        this.data ? this.data.model.get('code') : '',
        configuration
      ).then(_.bind(function () {
        bootbox.dialog({
          title: _.i18n('offer.continueTitle'),
          message: _.i18n('offer.continueText'),
          onEscape: _.bind(function () {
            this.showPreconfItems(this.data);
          }, this),
          buttons: {
            success: {
              label: _.i18n('offer.continueSameCustomer'),
              className: 'btn-success',
              callback: _.bind(function () {
                this.displayProductConfigPopup();
              }, this)
            },
            main: {
              label: _.i18n('offer.continueOtherCustomer'),
              className: 'btn-primary',
              callback: _.bind(function () {
                this.openEndUserDialog();
              }, this)
            }
          }
        });
      }, this));
      this.productConfigDialog.hide();
    },

    onGridLoaded: function () {
      var fetchStatuses = App.request('preconf-items:statuses', true);
      $.when(fetchStatuses).done(_.bind(function (statuses) {
        this.initializeGrid(this.formatedStatus(statuses));
      }, this));
    },

    formatedStatus: function (statuses) {
      var selectDefault = this.view.selectDefault,
        gridStatuses = selectDefault + ':' + selectDefault;

      statuses.each(function (status) {
        gridStatuses += ';' + status.get('statusConst') + ':' +
          status.get('status');
      });

      return gridStatuses;
    },

    initializeGrid: function (gridStatuses) {
      var data = {
        url: App.request('preconf-items:get-collection-url'),
        rowCountUrl: App.request('preconf-items:get-count-url'),
        statuses: gridStatuses
      };
      this.view.displayGrid(data, true);
    },

    onLoadSelectedPreconfItems: function (first, last, params) {
      $.when(App.request('preconf-items:get-collection-to-page', params))
        .done(_.bind(function (offers) {
          this.view.triggerMethod('selectPreconfItem', first, last, offers);
          $('#content-blinder').hide();
        }, this))
        .fail(function () {
          $('#content-blinder').hide();
        });
    },

    onOfferUpdate: function (data) {
      $.when(App.request('preconf-items:update', data));
    }
  }, ControllerMixin);

  module.exports = PreconfItemsController;
});
