
define('template!preconfItemsViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content m-l-0 m-r-0">\n  <div class="well jq-grid-table-page">\n    <h2>' +
((__t = ( _.i18n('menu.preconf-items.Management') )) == null ? '' : __t) +
'</h2>\n    <div class="sub-caccounts-table">\n      <div class="form-group add-new-block"\n      ' +
((__t = ( displayCreateBtn?'':'style="display: none;"' )) == null ? '' : __t) +
'>\n      <a target="_self" class="btn new-button add-btn-text">\n        <i class="mdi-content-add"></i>\n        <span>' +
((__t = ( _.i18n('preconf-items.new') )) == null ? '' : __t) +
'</span>\n      </a>\n        <div class="js-action-buttons-region offers-action-button f-r"></div>\n        </div>\n      <table id="jq-grid-list" class="cacountTable" role="grid"></table>\n      <div id="jq-grid-pager-list"></div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

