/**
 * Created by OLD on 28/05/2019.
 */
define('preconfItemsKanbanView',[
  'module',
  'underscore',
  'backbone',
  'marionette',
  'app',
  'jquery',
  'settings',
  'notificationCenter',
  'logotoolUtils',
  'template!preconfItemsKanbanViewTpl',
  'rolesMixin',
  'entities/preconf_items_kanban'
], function (
  module,
  _,
  Backbone,
  Marionette,
  App,
  $,
  Settings,
  NotificationCenter,
  logotoolUtils,
  viewTpl,
  RolesMixin
) {
  'use strict';

  var kanbanView = Marionette.LayoutView.extend({
    template: viewTpl,

    triggers: {
      'click @ui.addNew': 'addNew' //Caught by controller
    },

    ui: {
      downloadPdf: '.pdf-download',
      addNew: '.new-button'
    },

    regions: {
      kanbanFilters: '#preconf-items-filters-region',
      kanbanBoard: '#preconf-items-board-region'
    },

    onShow: function () {
      $.material.init();
      NotificationCenter.getInstance().addListener(
        this,
        NotificationCenter.events.CHANGE_INFO_ORDER,
        this.onMessage
      );
    },

    onMessage: function (response) {
      $.when(App.request('preconf_items-kanban:new:model', response.content))
        .done(_.bind(function (model) {
          var index = -1;
          _.each(this.options.cardCollections, _.bind(function (item) {
            if (item.collection.get(model.get('poi'))) {
              model.set('cardColor', response.content.cardColor);
              index = _.indexOf(item.collection.models, _.findWhere(item.collection.models, {id: model.get('poi')}));
              item.collection.remove(model.get('poi'));

            }
            if (item.status === response.content.status && response.content.commandType === 'corporateTemplate') {
              if (index !== -1) {
                item.collection.add(model, {at: index});
              } else {
                item.collection.add(model);
              }
              index = -1;
            }
          }, this));
        }, this));
    },

    serializeData: function () {
      return {
        design2: Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2]
      };
    }
  });

  module.exports = kanbanView;
});

